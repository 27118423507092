/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title }) {


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${title} | Metalkrat - producent bram przesuwnych, teleskopowych, uchylnych, specjalnych oraz ogrodzeń i konstrukcji stalowych`}
      meta={[
        {
          name: `keywords`,
          content: "bramy przesuwne, bramy teleskopowe, bramy uchylne, bramy łukowe, konstrukcje stalowe, ogrodzenia, bramy garażowe, bramy, bramy podwójniezsuwane"
        },
        {
          name: `description`,
          content: "Metalkrat to firma specjalizująca się w bramach oraz ogrodzeniach, a także konstrukcjach stalowych. Głównym produktem są bramy teleskopowe, pozwalające zamontować bramę nawet na małych posesjach z brakiem miejsca na jej odsunięcie"
        },
        {
          property: `og:title`,
          content: "Metalkrat - producent bram przesuwnych, teleskopowych, uchylnych, specjalnych oraz ogrodzeń i konstrukcji stalowych",
        },
        {
          property: `og:description`,
            content: "Metalkrat to firma specjalizująca się w bramach oraz ogrodzeniach, a także konstrukcjach stalowych. Głównym produktem są bramy teleskopowe, pozwalające zamontować bramę nawet na małych posesjach z brakiem miejsca na jej odsunięcie"
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
